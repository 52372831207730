import React, { useState } from 'react';
import './index.css';

function Card() {
    const inputStyle = {
      border: 'none',
      outline: 'none',
      fontSize: 18,
      width: '100%', // Ensure inputs take up full width
      borderRadius:'0'
    };
  
    const buttonStyle = {
      border: 'none',
      outline: 'none',
      fontSize: 22,
      fontWeight: 1900,
      cursor: 'pointer',
      width: '100%', // Ensure button takes up full width
      backgroundColor:'#1C6B73',
      color:'white',
      padding: '2px 8px', // Add some padding for a better look

    };
  
    const [showPopup, setShowPopup] = useState(false);
  
    const handlePlanYourTripClick = () => {
      setShowPopup(true);
    };
  
    return (
      <>
        <div className="card-container">
          <h1 style={{fontSize: 35, lineHeight: 1, letterSpacing: 0.5, textAlign: 'center', color: 'black'}}>Craft Your AI Personalized Itinerary</h1>
          <p style={{ fontSize: 18 }}>Enter Your Destination</p>
  
          <input
            type="text"
            name="myInput"
            placeholder="E.g Ooty"
            style={inputStyle}
          /><br />
          <input
            // className="desktop-input"
            type="text"
            placeholder="E.g 3 days"
            style={inputStyle}
          /><br />
  
          <button onClick={handlePlanYourTripClick} style={buttonStyle}>
            Plan your Trip
          </button><br />
          <p>
            By clicking Plan your Trip, you agree to our Terms and Conditions and
            Privacy Policy.
          </p>
        </div>
  
        {showPopup && (
          <div className="popup">
            <p>
              Thanks for your interest! You are added to our waiting list. We will
              get back to you with exciting itineraries when we are ready.
            </p>
            <button style={buttonStyle} onClick={() => setShowPopup(false)}>Close</button>
          </div>
        )}
      </>
    );
  }

  export default Card;
  